import { request, withTransactionId } from '@rea-group/fs-web-utilities';

import { REA_BASE_URL } from '../constants/urls';
import { SubmitFormData } from '../components/forms/QuickLeads/types';
import { generateRACBFormEndpoint } from './generateRequestURL';

export const submitForm = (formData: SubmitFormData, eventSource: string) => {
  return request.fetchWithAuth(
    REA_BASE_URL,
    generateRACBFormEndpoint(eventSource),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(formData),
    },
    withTransactionId
  );
};

export const handleResponse = (response: Response) => {
  if (response.status >= 400) {
    throw new Error(`${response.status} ${response.statusText}`);
  }
  return response.json();
};

export const submitFormWithoutAuth = (
  formData: unknown,
  eventSource: string
) => {
  const enquiryUrl = generateRACBFormEndpoint(eventSource);
  return request.fetch(
    enquiryUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(formData),
    },
    withTransactionId
  );
};

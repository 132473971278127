import { extractDigits } from '@rea-group/fs-web-utilities';

export const isPhoneNumberValid = (value: string | undefined) => {
  return !!(value && extractDigits(value).match(/^0\d{9}$/));
};

export const isNameValid = (value: string | undefined) =>
  !!(value && value.match(/^[a-zA-Z-'\s]+$/));

export const isPostcodeValid = (postcode: string | undefined) => {
  const regex = /^\d{4}$/;
  return !!(postcode && regex.test(postcode));
};

export const isEmailValid = (value: string | undefined) =>
  !!(value && value.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i));

import { Alert } from '@construct-kit/core';
import styled, { keyframes } from 'styled-components';

const showAlertAnimation = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

const StyledAlert = styled(Alert)`
  animation: ${showAlertAnimation} 800ms ease-out both;
`;

export default StyledAlert;

import { Component } from 'react';

import { logError } from '../../../utilities/errorHandler';

type Props = {
  component: string;
  children: React.ReactNode;
};

class ErrorBoundary extends Component<Props> {
  componentDidCatch(error: any, info: any) {
    logError(`[${this.props.component}]: ${error}`, {
      componentStack: info.componentStack,
      component: this.props.component,
    });
  }

  render() {
    return this.props.children;
  }
}
export default ErrorBoundary;

import React from 'react';

import AnimatedAlert from '../../shared/AnimatedAlert';

const ErrorState = () => (
  <AnimatedAlert variant="error">
    We were unable to submit your details, please try again.
  </AnimatedAlert>
);

export default ErrorState;
